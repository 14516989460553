import React from "react"

import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import Layout from "../components/Layout"

const Error = () => {
  return (
    <Layout>
      <Seo title="Recurso o página no encontrada" />
      <main className="error-page">
        <div className="error-container">
          <figure>
            <StaticImage src="../assets/images/404.svg" alt="No encontrado img" layout="fixed" height={300} placeholder="blurred" />
            <figcaption>
              <h2>Oops!</h2>
              <h3>No se encontró lo que buscas <span role="img" aria-label="oops" aria-labelledby="oops">😅</span></h3>
            </figcaption>
          </figure>
          <Link to="/" className="btn">
            <span role="img" aria-label="back to home" aria-labelledby="back to home">🏡</span>
            &nbsp;Regresar al inicio
          </Link>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


export default Error
